<template>
  <div>
    <div class="recordlist-link">
      <el-button type="text" @click="openRecordListDialog()"
        >跟进历史记录</el-button
      >
    </div>
    <el-form
      v-if="showEdit"
      :model="editFollowRecordInfo"
      ref="editFollowRecordInfo"
      :rules="editFollowRecordInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="开始时间" label-width="100px" prop="starttime">
            {{ followInfo?.starttime }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="类型" label-width="100px" prop="type">
            {{ computeType }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="showEdit">
        <el-col :span="24">
          <el-form-item label="目标" label-width="100px" prop="target">
            <el-input
              type="textarea"
              :rows="5"
              maxlength="500"
              v-model="editFollowRecordInfo.target"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="showEdit">
        <el-col :span="24">
          <el-form-item label="结果" label-width="100px" prop="result">
            <el-input
              type="textarea"
              :rows="5"
              maxlength="500"
              v-model="editFollowRecordInfo.result"
              placeholder="请输入跟进结果"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="showEdit">
      <div class="next-follow">下次跟进</div>
      <el-form if-show="showEdit"
        :model="newFollowRecordInfo"
        ref="newFollowRecordInfo"
        :rules="newFollowRecordInfoRules"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="目标" label-width="100px" prop="target">
              <el-input
                type="textarea"
                :rows="5"
                maxlength="500"
                v-model="newFollowRecordInfo.target"
                placeholder="请输入下次跟进目标"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer" v-if="showEdit">
      <el-button type="primary" @click="edit_save()">确定</el-button>
    </div>

    <!--record log dialog-->
    <el-dialog
      title="跟进历史记录"
      :close-on-click-modal="false"
      width="1000px"
      v-model="showRecordListDialog"
      @open="openRecordLitDialog"
      @close="closeRecordListDialog()"
    >
      <div class="recordListBox">
        <el-table
          :data="recordListPage.list"
          v-loading="showRecordListLoading"
          element-loading-text="拼命加载中"
          border
          width="width:100%; height:50%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="planid"
            label="跟进计划"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="customer.name"
            label="客户"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="uid"
            label="跟进人"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="target"
            label="跟进目标"
            width="200"
          ></el-table-column>
          <el-table-column prop="result" label="跟进结果"></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="50"
            :formatter="formatterRecordStatus"
          ></el-table-column>
        </el-table>
        <el-pagination
          @current-change="devlogChangeRecordList"
          :current-page="recordListPage.pageNum"
          :page-size="recordListPage.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="recordListPage.total"
        >
        </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeRecordListDialog()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FOLLOW from "../../api/biz/api_followplan";
import moment from "moment";
import FOLLOWRECORD from "../../api/biz/api_followrecord";

export default {
  name:"followPlanRecord",
  data() {
    return {
      //MAP: undefined,
      followRecordId: undefined,
      followInfo: {},
      newFollowRecordInfo: {},
      editFollowRecordInfo: {},
      showRecordListDialog: false,
      recordListPage: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      showRecordListLoading: false,
      showEdit: false,

      types: [
        { name: "新客开拓", value: 0 },
        { name: "创建年度验证计划", value: 1 },
      ],

      editFollowRecordInfoRules: {
        target: [
          { required: true, message: "请输入跟进目标", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "长度在 2 到 500 个字符",
            trigger: "blur",
          },
        ],
        result: [
          { required: true, message: "请输入跟进结果", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "长度在 2 到 500 个字符",
            trigger: "blur",
          },
        ],
      },

      newFollowRecordInfoRules: {
        target: [
          { required: true, message: "请输入下次跟进目标", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "长度在 2 到 500 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    computeStarttime() {
      if (this.followInfo) {
        return this.followInfo?.starttime;
      }
      return "no value";
    },
    computeType() {
      let type = this.types.filter((t) => t.value == this.followInfo?.type);
      if (type) {
        return type[0]?.name;
      }
      return "no value";
    },
    auth: {}
  },
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },
    initPageData() {
      //edit followInfo and followRecord
      let followPlanId = this.$route.query.followPlanId;
      if (followPlanId) {
        FOLLOW.get({
          id: followPlanId,
        }).then((res) => {
          if (200 == res.code) {
            if (res.data) {
              this.followInfo = res.data;
              if(this.followInfo?.status==0){
                this.showEdit=false;
              }
            } else {
              this.$message.error("跟进方案没有找到");
              this.showEdit = false;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
        FOLLOWRECORD.getLatestByFollowPlanId({
          followPlanId: followPlanId,
        }).then((res) => {
          if (200 == res.code) {
            if (res.data) {
              this.editFollowRecordInfo = res.data;
              this.followRecordId = res.data.id;
              this.showEdit = true;
            } else {
              this.$message.error("跟进记录没有找到");
              this.showEdit = false;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    edit_save() {
      this.$refs["editFollowRecordInfo"].validate((valid1) => {
        if (valid1) {
          this.$refs["newFollowRecordInfo"].validate((valid2) => {
            if (valid2) {
              let editFollowRecord = {};
              editFollowRecord.id = this.followRecordId;
              editFollowRecord.target = this.editFollowRecordInfo?.target;
              editFollowRecord.result = this.editFollowRecordInfo?.result;
              editFollowRecord.nextTarget = this.newFollowRecordInfo?.target;

              FOLLOWRECORD.info(editFollowRecord)
                .then((res) => {
                  if (200 == res.code) {
                    //next follow info
                    this.$message.success("操作成功！");
                    if (res.data && res.data?.id) {
                      this.followRecordId = res.data.id;
                      this.editFollowRecordInfo = {};
                      this.editFollowRecordInfo.target =
                        this.newFollowRecordInfo.target;
                      this.newFollowRecordInfo.target = "";
                      this.showEdit = true;
                    }
                  } else {
                    this.$message.error(res.msg);
                  }
                })
                .catch(() => console.error("promise catch err1"));
            }
          });
        }
      });
    },

    openRecordListDialog() {
      this.showRecordListDialog = true;
      this.initRecordList();
    },
    closeRecordListDialog() {
      this.showRecordListDialog = false;
    },
    initRecordList() {
      this.showRecordListLoading = true;
      FOLLOWRECORD.list({
        pageNum: this.recordListPage?.pageNum,
        pageSize: this.recordListPage?.pageSize,
        planid: this.followInfo?.id,
      }).then((res) => {
        this.showRecordListLoading = false;
        if (200 == res.code) {
          let data = res?.pageData;
          this.recordListPage.total = data?.total;
          this.recordListPage.list = data?.list;
        } else {
          this.$message.error(res?.msg);
          this.recordListPage.list = [];
        }
      });
    },
    formatterRecordStats(index) {
      let record = this.recordListPage.list[index];
      let status = record?.status;
      return status == 1 ? "可用" : status == 0 ? "关闭" : status;
    },
    devlogChangeRecordList(pageNum) {
      this.recordListPage.pageNum = pageNum;
      this.initRecordList();
    },
  },
};
</script>

<style scoped>
.next-follow {
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  opacity: 0.7;
}
.recordlist-link {
  line-height: 1em;
  margin-bottom: 20px;
  padding-right: 30px;
  text-align: right;
}
</style>